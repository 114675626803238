<template>
  <!-- List of pending actions -->
  <div>
    <div
      v-for="item in customListItem"
      :key="item.id"
      class="
        flex
        items-center
        justify-between
        px-4
        py-4
        bg-tertiary-gray-lighter
        sm:bg-white
        rounded-xl
        mb-4
        md:mb-6
      "
    >
      <div class="flex items-center">
        <!-- Figure caption -->
        <span
          class="figure-caption"
          :style="{
            backgroundImage: 'url(' + require(`@public/img/icons/${item.iconName}.svg`) + ')',
          }"
        >
        </span>
        <!-- Title and status -->
        <div class="ml-2 flex flex-col">
          <h2 class="pending-actions-list-title">{{ item.title }}</h2>
          <XeActionStatus :status="item.status" />
        </div>
      </div>
      <!-- Button to fill data -->
      <AppButton
        v-if="item.status === 'pending'"
        theme="primary"
        class="list-button"
        @click="$router.push(item.buttonRoute)"
      >
        Start
      </AppButton>
      <!-- Button to edit -->
      <AppButton
        v-if="item.status === 'completed'"
        theme="secondary"
        class="list-button"
        @click="$router.push(item.buttonRoute)"
      >
        Edit
      </AppButton>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton/AppButton'
import XeActionStatus from '@/components/XeActionStatus/XeActionStatus'

import { computed } from '@vue/composition-api'
import { useDashboardStore } from '@/stores/dashboard'

export default {
  components: {
    AppButton,
    XeActionStatus,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const dashboardStore = useDashboardStore()

    // Submission Status
    // 1. Company details
    const isCompanyDetailsReadyToSubmit = computed(
      () => dashboardStore.getCompanyDetailsCompletionStatus
    )
    // 2. Business Purposes
    const isBusinessPurposesReadyToSubmit = computed(
      () => dashboardStore.getBusinessPurposesCompletionStatus
    )
    // 3. Documents
    const isAdditionalDocumentsReadyToSubmit = computed(
      () => dashboardStore.getAdditionalDocumentsCompletionStatus
    )

    // 4. Business Owners
    // TODO: Improve the logics for adding at least one director based on country and company type
    const isBusinessOwnersReadyToSubmit = computed(
      () => dashboardStore.getIsDirectorAdded && dashboardStore.getIsEveryIndividualsValid
    )

    const calculatePaListItemStatus = (listItem) => {
      let activeItemStatus
      switch (listItem.id) {
        case 'additionalDocuments':
          activeItemStatus = isAdditionalDocumentsReadyToSubmit.value
          break
        case 'businessPurpose':
          activeItemStatus = isBusinessPurposesReadyToSubmit.value
          break
        case 'businessOwners':
          activeItemStatus = isBusinessOwnersReadyToSubmit.value
          break
        case 'companyDetails':
          activeItemStatus = isCompanyDetailsReadyToSubmit.value
          break
      }
      listItem['status'] = activeItemStatus ? 'completed' : 'pending'
      return listItem
    }
    // Map everything
    const customListItem = computed(() => props.list.map((item) => calculatePaListItemStatus(item)))

    return {
      customListItem,
      isCompanyDetailsReadyToSubmit,
    }
  },
}
</script>

<style lang="postcss" scoped>
.pending-actions-list {
  &-title {
    @apply type-caption-bold;

    @screen md {
      @apply type-subtitle-bold;
    }
  }
}
.figure-caption {
  @apply bg-tertiary-gray-lighter border border-gray-darker w-10 h-10 rounded-xl;
  @apply bg-no-repeat;
  background-position: 50%;

  @screen md {
    @apply bg-tertiary-gray-lighter border-none w-10 h-10 rounded-full;
  }
}

.list-button {
  border-radius: 0.34394rem !important;
}
>>> .button-inner {
  font-size: 0.68788rem;
  padding: 0.51594rem 1.03181rem !important;
  line-height: 1.03181rem;

  @screen sm {
    @apply type-caption-bold;
    padding: 0.34394rem 0.68788rem !important;
  }

  @screen md {
    line-height: inherit;
    font-size: 0.8125rem;
    padding: 0.03125rem 1.375rem 0.03125rem 1.3125rem !important;
  }
}
</style>
