<template>
  <div>
    <div v-if="isDashboardLoaded" class="pending-actions">
      <div class="pending-actions-card relative">
        <!-- Title -->
        <h2 class="pending-actions-title">{{ title }}</h2>
        <h4 v-if="isExtraInfoRequired" class="pending-actions-description">
          In order to start sending money, additional documentation is needed
        </h4>
        <h4 v-else class="pending-actions-description">{{ description }}</h4>

        <!-- List of pending actions -->
        <DashboardPendingActionsList
          v-if="pendingActionsList.length > 0"
          class="pending-actions-list"
          :list="pendingActionsList"
        />

        <!-- Disclaimer -->
        <div class="pending-actions-disclaimer">
          <AppInputCheckbox v-model="agreeAllOwners" class="mr-2" />
          <p class="type-caption text-gray">{{ checkboxText }}</p>
        </div>

        <!-- Submit -->
        <div class="pending-actions-button-wrapper">
          <AppButton
            :disabled="isSubmitButtonDisabled"
            type="submit"
            class="pending-actions-button"
            @click.stop.prevent="onPendingActionSubmit"
            >Submit</AppButton
          >
        </div>
      </div>
      <!-- Mobile Button -->
      <div class="fixed w-full z-50 bottom-4 sm:hidden">
        <div class="bg-white mx-4 rounded-xl">
          <AppButton
            :disabled="isSubmitButtonDisabled"
            type="submit"
            class="w-full"
            @click.stop.prevent="onPendingActionSubmit"
            >Submit</AppButton
          >
        </div>
      </div>
    </div>
    <!-- Loader -->
    <div v-else>
      <AppSpinnerBig :loading="!isDashboardLoaded" transition="fade" class="spinner" />
    </div>
  </div>
</template>

<script>
import { reactive, computed, ref } from '@vue/composition-api'
import { useRouter, checkCurrentRouteAndRedirect } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'

import AppButton from '@/components/AppButton/AppButton'
import AppSpinnerBig from '@/components/AppSpinnerBig/AppSpinnerBig'
import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox.vue'
import companyTypeMapper from '@/composables/companyTypeMapper'
import { randomPercentage } from '@/utils/math'
import { getRegionFromCountry } from '@/utils/i18n'

import { useAppStore } from '@/stores/app'

import DashboardPendingActionsList from './DashboardPendingActionsList.vue'
import { useDashboardStore } from '@/stores/dashboard'

export default {
  name: 'DashboardPendingActions',
  components: {
    AppButton,
    AppSpinnerBig,
    AppInputCheckbox,
    DashboardPendingActionsList,
  },
  setup() {
    const mq = reactive(useMediaQuery())
    const router = useRouter()
    const dashboardStore = useDashboardStore()
    const appStore = useAppStore()

    // Default values
    const agreeAllOwners = ref(dashboardStore.agreeAllOwners)
    const { title, description } = dashboardStore.getStpPageById('PendingActions')
    const { checkboxText } = dashboardStore.getStpPageById('PendingActions').content
    const pendingActionsList = computed(() => dashboardStore.getPendingListItems)
    const isDashboardLoaded = computed(() => appStore.isDashboardLoaded)
    const isSubmitButtonDisabled = computed(
      () => !(agreeAllOwners.value && dashboardStore.getAllPendingReadyToSubmit)
    )
    const isExtraInfoRequired = computed(() => dashboardStore.getIsExtraInfoRequired)
    const pendingActionsConfig = computed(() => dashboardStore.pendingActionsConfig)

    // Methods
    const goToError = (type) => {
      router.push({
        name: 'PendingActionError',
        props: {
          errorType: type,
        },
      })
    }

    // Update additional information status
    const questionsAndAnswers = computed(() => dashboardStore.questionsAndAnswers)

    const goToTransferXe = () => {
      // remove persisted questionsAndAnswers, documents and auth personnel from store
      dashboardStore.setClearSessionStore()
      window.removeEventListener('beforeunload', window.handleWindowClose)

      const redirectUrl = dashboardStore.getRedirectUrl()
      localStorage.clear()
      window.location = redirectUrl
    }
    const corpProfile = computed(() => dashboardStore.corpProfile)

    const goToTargetRoute = (nameRoute) => checkCurrentRouteAndRedirect(router, nameRoute)

    // Rollout STP approval logic
    const rolloutStpApproval = (stpRedirection, transferXeRedirection) => {
      // STP flow rollout
      const country = corpProfile.value.regionName || corpProfile.value.mainContact.address.country
      if (
        randomPercentage(pendingActionsConfig.value.rolloutPercentage) &&
        pendingActionsConfig.value.rolloutRegion.includes(getRegionFromCountry(country))
      ) {
        // Run STP approval flow
        stpRedirection('DashboardSubmit')
      } else {
        // no need to run stp flow
        transferXeRedirection()
      }
    }

    const onPendingActionSubmit = async () => {
      appStore.isDashboardLoaded = false
      dashboardStore.displayHVModal = false
      const country = corpProfile.value.registrationAddress.country.toUpperCase()
      const companyType = companyTypeMapper(corpProfile.value.companyDetails.companyType, country)

      // Business purpose data
      const additionalInformationWithoutParentheses = questionsAndAnswers.value.map((item) => {
        if (item.id === 'destinationCountries') {
          return { ...item, value: item.value.join() }
        }
        return { ...item, value: item.value }
      })

      // Business owners data
      const getAuthorizedPersonnelList = dashboardStore.authorizedPersonnel
      // set occupation for each person if other is selected
      const authorizedPersonnelListWithOccupation = getAuthorizedPersonnelList.map((eachPerson) => {
        return { ...eachPerson, occupation: eachPerson.role ?? '' }
      })
      const authorizedFormData = {
        id: 'authorizedPersonnel',
        values: authorizedPersonnelListWithOccupation,
      }

      // EKYC value
      let kycInformation = [...additionalInformationWithoutParentheses, authorizedFormData]
      const userCertification = agreeAllOwners.value

      const isCompanyAllowedToSendCompanyInfo =
        dashboardStore.pendingActions.data.kycRequirements.some(
          (e) => e.id === 'companyInformation'
        )

      // put companyInformation into kycInformation
      kycInformation = [...kycInformation, dashboardStore.getCompanyInfoFields]

      try {
        // Submit pending actions
        const submitResponse = await dashboardStore.getPendingActions({
          country,
          companyType,
          kycInformation: kycInformation,
          userCertification,
        })
        if (submitResponse.verified.toLowerCase() === 'true') {
          if (isCompanyAllowedToSendCompanyInfo && !isExtraInfoRequired.value) {
            // STP flow rollout
            rolloutStpApproval(goToTargetRoute, goToTransferXe)
          } else {
            //if submitting extra info, have user wait in galileo
            // no need to run stp flow
            goToTransferXe()
          }
        }
      } catch (error) {
        goToError('dashboard')
      }
    }

    return {
      mq,
      title,
      description,
      checkboxText,
      pendingActionsList,
      isDashboardLoaded,
      agreeAllOwners,
      goToError,
      onPendingActionSubmit,
      isSubmitButtonDisabled,
      isExtraInfoRequired,
    }
  },
}
</script>

<style lang="postcss" scoped>
.pending-actions {
  @apply pt-20 mb-8 h-full;

  @screen md {
    @apply pt-6 mb-0;
  }

  &-card {
    @apply px-4 pt-4 m-auto h-full w-full;

    @media (max-width: 320px) {
      padding-bottom: calc(36rem + 100%);
    }

    padding-bottom: calc(28rem + 100%);

    @screen sm {
      @apply max-w-1/2xl pt-0 px-0 pb-0;
    }
  }

  &-title {
    @apply text-xl leading-7 font-medium;

    @screen sm {
      @apply leading-8 text-2xl;
    }

    @screen md {
      @apply type-stp-title;
    }
  }

  &-description {
    @apply type-caption text-gray pt-4;

    @screen sm {
      @apply pt-6;
    }

    @screen md {
      @apply text-sm;
      line-height: 1.125rem;
    }
  }

  &-list {
    @apply pt-6;

    @screen md {
      @apply pt-10;
    }
  }

  &-disclaimer {
    @apply type-caption flex items-center py-5 px-6 mb-10;
    @apply border border-tertiary-grey-darkest rounded-xl;

    @screen sm {
      @apply mt-6 mb-0;
    }

    @screen md {
      @apply mt-0;
    }
  }

  &-button {
    &-wrapper {
      @apply hidden;

      @screen sm {
        @apply flex justify-end mt-6;
      }

      @screen md {
        @apply mt-10;
      }
    }

    >>> .button-inner {
      @screen sm {
        @apply px-6 py-3 rounded-xl !important;
      }
    }
  }
}

.button {
  @apply rounded-xl !important;
}
</style>
