<template>
  <Component :is="renderComponent" />
</template>

<script>
import { computed } from '@vue/composition-api'

import DashboardPendingActionsSTPFlow from './DashboardPendingActionsSTPFlow/DashboardPendingActionsSTPFlow.vue'
import DashboardPendingActionsOldFlow from './DashboardPendingActionsOldFlow.vue'
import { useDashboardStore } from '@/stores/dashboard'

export default {
  name: 'DashboardPendingActionsWrapper',
  components: {
    DashboardPendingActionsOldFlow,
    DashboardPendingActionsSTPFlow,
  },
  setup() {
    const dashboardStore = useDashboardStore()
    const renderComponent = computed(() => {
      return dashboardStore.getAccountIsAllowedForStp
        ? 'DashboardPendingActionsSTPFlow'
        : 'DashboardPendingActionsOldFlow'
    })

    return {
      renderComponent,
    }
  },
}
</script>
