<template>
  <div>
    <div v-if="isDashboardLoaded">
      <AppCard :size="'1/2xl'">
        <template #header>
          <div class="flex justify-between pt-6 sm:pt-12 px-4 sm:px-12 mb-5">
            <div>
              <div
                :class="mq.current !== 'xs' ? 'type-subtitle-bold' : 'type-body'"
                class="text-text-gray-primary flex"
              >
                <span class="welcome" :title="registeredName" data-private
                  >Welcome {{ registeredName }}</span
                >
                <span role="img" aria-label="Hand shake">&#128075;</span>
              </div>
              <h1 :class="mq.current !== 'xs' ? 'type-h1' : 'type-h2'">Pending Actions</h1>
            </div>
          </div>
        </template>
        <div class="-mt-12 sm:mt-0">
          <div class="pb-7 type-caption text-text-gray-primary">
            <span v-if="isExtraInfoRequired"
              >In order to start sending money, additional documentation is needed</span
            >
            <span v-else>In order to start sending money, complete your pending actions</span>
          </div>

          <div v-if="documentaryEvidenceStatus.length > 0" class="type-subtitle-bold mb-4">
            Documentary evidence
          </div>

          <div class="rounded-xl block mb-4">
            <AppList v-for="(item, index) in documentaryEvidenceStatus" :key="index">
              <AppListClickItem @itemClick="goToDocumentaryEvidence(item)">
                <template #figure>
                  <AppFigure :theme="'tertiary-gray'" size="w-14 h-14">
                    <AppIcon>
                      <IconDocument />
                    </AppIcon>
                  </AppFigure>
                </template>

                <template #left>
                  <AppListItemTitle
                    class="pending-actions-caption-bold capitalize"
                    v-text="item.title"
                  ></AppListItemTitle>
                  <AppActionStatus
                    class="pending-actions-status"
                    :status="item.status"
                  ></AppActionStatus>
                </template>

                <template #action>
                  <div class="text-text-gray-primary">
                    <AppIcon>
                      <IconChevronRight v-if="item.status == 'pending'" />
                      <IconEdit v-else />
                    </AppIcon>
                  </div>
                </template>
                <!-- Add hr only when items are more than two or further -->
                <hr
                  v-if="index !== documentaryEvidenceStatus.length - 1"
                  class="text-tertiary-gray-darker"
                />
              </AppListClickItem>
            </AppList>
          </div>

          <div v-if="!isAdditionalInformationHidden" class="type-subtitle-bold">
            Additional Information
          </div>

          <div class="rounded-xl block mb-4">
            <AppList v-for="item in additionalInformationStatus" :key="item.title">
              <template v-if="!item.hidden">
                <AppListClickItem @itemClick="goToTargetRoute(item.name)">
                  <template #figure>
                    <AppFigure :theme="'tertiary-gray'" size="w-14 h-14">
                      <AppIcon>
                        <IconListDropdown />
                      </AppIcon>
                    </AppFigure>
                  </template>

                  <template #left>
                    <AppListItemTitle
                      class="capitalize pending-actions-caption-bold"
                      v-text="item.title"
                    ></AppListItemTitle>
                    <AppActionStatus
                      class="pending-actions-status"
                      :status="item.status"
                    ></AppActionStatus>
                  </template>

                  <template #action>
                    <div class="text-text-gray-primary">
                      <AppIcon>
                        <IconChevronRight v-if="item.status == 'pending'" />
                        <IconEdit v-else />
                      </AppIcon>
                    </div>
                  </template>
                </AppListClickItem>
              </template>
            </AppList>
          </div>

          <div
            class="
              flex
              justify-items-center
              items-center
              space-x-2
              p-6
              mb-6
              border border-gray-light
              rounded-2xl
            "
          >
            <AppInputCheckbox v-model="agreeAllOwners" />
            <p class="type-caption pl-2">
              I certify the information in this application is correct, accurate and complete.
            </p>
          </div>
          <div>
            <AppCardFooter class="pending-actions-footer">
              <AppButton
                type="submit"
                :disabled="isSubmitButtonDisabled"
                class="pending-actions-button"
                @click="onPendingActionSubmit"
              >
                Submit
              </AppButton>
            </AppCardFooter>
          </div>
        </div>
      </AppCard>
      <RouterView />
    </div>
    <div v-else>
      <AppSpinnerBig :loading="!isDashboardLoaded" transition="fade" class="spinner" />
    </div>
  </div>
</template>

<script>
import { useRouter, checkCurrentRouteAndRedirect } from '@/composables/useRouter'
import { reactive, computed, watch, ref } from '@vue/composition-api'

import { useAppStore } from '@/stores/app'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppSpinnerBig from '@/components/AppSpinnerBig/AppSpinnerBig'
import AppList from '@/components/AppList/AppList'
import AppListClickItem from '@/components/AppListClickItem/AppListClickItem'
import AppFigure from '@/components/AppFigure/AppFigure'

import AppListItemTitle from '@/components/AppListItemTitle/AppListItemTitle'
import AppIcon from '@/components/AppIcon/AppIcon'
import { useMediaQuery } from '@/composables/useMediaQuery'

import {
  IconChevronRight,
  IconDocument,
  IconEdit,
  IconListDropdown,
} from '@moneytransfer.ui/euronet-icons'

import AppActionStatus from '@/components/AppActionStatus/AppActionStatus'
import companyTypeMapper from '@/composables/companyTypeMapper'

import AppInputCheckbox from '@/components/AppInputCheckbox/AppInputCheckbox.vue'
import { useDashboardStore } from '@/stores/dashboard'

export default {
  name: 'DashboardPendingActions',
  components: {
    AppButton,
    AppCard,
    AppCardFooter,
    AppList,
    AppListClickItem,
    AppFigure,
    AppListItemTitle,
    AppIcon,
    IconChevronRight,
    IconDocument,
    IconEdit,
    IconListDropdown,
    AppActionStatus,
    AppSpinnerBig,
    AppInputCheckbox,
  },
  props: {
    documentName: {
      type: Boolean,
    },
  },
  setup() {
    const mq = reactive(useMediaQuery())
    const router = useRouter()
    const dashboardStore = useDashboardStore()

    const appStore = useAppStore()

    const goToTransferXe = () => {
      // remove persisted questionsAndAnswers, documents and auth personnel from store
      dashboardStore.setClearSessionStore()
      window.removeEventListener('beforeunload', window.handleWindowClose)

      const redirectUrl = dashboardStore.getRedirectUrl()
      localStorage.clear()
      window.location = redirectUrl
    }

    const isDashboardLoaded = computed(() => appStore.isDashboardLoaded)
    const documentaryEvidenceStatus = computed(() => dashboardStore.documentaryEvidenceStatus)
    const additionalInformationStatus = computed(() => dashboardStore.additionalInformationStatus)
    const documentaryEvidenceData = computed(() => dashboardStore.uploadedFile)
    const isExtraInfoRequired = computed(() => dashboardStore.getIsExtraInfoRequired)
    const isAdditionalInformationHidden = computed(() =>
      additionalInformationStatus.value
        .map((item) => item.hidden)
        .every((isHidden) => isHidden === true)
    )
    const agreeAllOwners = ref(dashboardStore.agreeAllOwners)

    // Update documentary evidence status
    const updateDocumentStatus = (data) => {
      if (documentaryEvidenceStatus?.value?.length > 0) {
        const newStatus = documentaryEvidenceStatus.value.map((eachStatus) => {
          let resObject = { ...eachStatus }

          Object.entries(data).forEach(([docId, doc]) => {
            if (docId === eachStatus.id) {
              resObject = { ...resObject, status: doc?.s3key ? 'completed' : 'pending' }
            }
          })
          return resObject
        })
        dashboardStore.documentaryEvidenceStatus = newStatus
      }
    }

    updateDocumentStatus(documentaryEvidenceData)

    // Listening documentary evidence data updating
    watch(documentaryEvidenceData, (data) => updateDocumentStatus(data), { deep: true })

    // Update additional information status
    const questionsAndAnswers = computed(() => dashboardStore.questionsAndAnswers)

    // Check status
    const checkStatus = (arr, keyword) => {
      return arr.map((i) => i.status === keyword)
    }
    const isSubmitButtonDisabled = computed(() => {
      if (agreeAllOwners.value) {
        const docEvidenceStatus = checkStatus(documentaryEvidenceStatus.value, 'completed')
        const filteredAdditionalInformationStatus = additionalInformationStatus.value.filter(
          (status) => status.hidden === false
        )
        const additionalInfoStatus = checkStatus(filteredAdditionalInformationStatus, 'completed')
        return ![...docEvidenceStatus, ...additionalInfoStatus].every((element) => element)
      }
      return true
    })

    const corpProfile = computed(() => dashboardStore.corpProfile)
    const registeredName = computed(() => {
      if (corpProfile?.value?.companyDetails) {
        return corpProfile.value.companyDetails.registeredName
      }
      return ''
    })
    const onPendingActionSubmit = async () => {
      appStore.isDashboardLoaded = false
      dashboardStore.displayHVModal = false
      const country = corpProfile.value.registrationAddress.country.toUpperCase()
      const companyType = companyTypeMapper(corpProfile.value.companyDetails.companyType, country)

      const questionsAndAnswersForDb = questionsAndAnswers.value.filter(field => {
        return field.id !== "planToUseAccount" && field.id !== "heardAboutUs"
      })
      
      const additionalInformationWithoutParentheses = questionsAndAnswersForDb.map((item) => {
        if (item.id === 'destinationCountries') {
          return { ...item, value: item.value.join() }
        }
        return { ...item, value: item.value }
      })

      const getAuthorizedPersonnelList = dashboardStore.authorizedPersonnel
      const authorizedFormData = {
        id: 'authorizedPersonnel',
        values: getAuthorizedPersonnelList,
      }

      const kycInformation = ref([...additionalInformationWithoutParentheses, authorizedFormData])
      const userCertification = agreeAllOwners.value

      // all pending action company types
      // UK limited, UK sole, US...
      const isAccountSupportingStp = dashboardStore.getAccountIsAllowedForStp

      const isCompanyAllowedToSendCompanyInfo =
        dashboardStore.pendingActions.data.kycRequirements.some(
          (e) => e.id === 'companyInformation'
        )

      // put companyInformation into kycInformation
      if (isCompanyAllowedToSendCompanyInfo) {
        kycInformation.value = [...kycInformation.value, dashboardStore.getCompanyInfoFields]
      }

      try {
        const submitResponse = await dashboardStore.getPendingActions({
          country,
          companyType,
          kycInformation: kycInformation.value,
          userCertification,
        })
        if (submitResponse.verified.toLowerCase() === 'true') {
          // determines if current user company type and country supports STP
          if (isAccountSupportingStp) {
            if (isCompanyAllowedToSendCompanyInfo) {
              // if first time submit, redirect to stp flow
              goToTargetRoute('DashboardSubmit')
            } else {
              //if submitting extra info, have user wait in galileo
              // no need to run stp flow
              goToTransferXe()
            }
          } else {
            appStore.isDashboardLoaded = true
            goToTargetRoute('ProcessingInfo')
          }
        }
      } catch (error) {
        goToError('dashboard')
      }
    }

    // Modal status
    const goToDocumentaryEvidence = (item) => {
      router.push({
        name: 'DocumentaryEvidence',
        params: {
          documentName: item.name.toLowerCase().replace(/\s+/g, '-'),
          documentId: item.id,
          documentTitle: item.title,
          documentStatus: item.status,
          showModal: true,
        },
      })
    }

    // routers
    const goToTargetRoute = (nameRoute) => checkCurrentRouteAndRedirect(router, nameRoute)

    const goToError = (type) => {
      router.push({
        name: 'PendingActionError',
        props: {
          errorType: type,
        },
      })
    }

    return {
      additionalInformationStatus,
      isSubmitButtonDisabled,
      documentaryEvidenceData,
      isDashboardLoaded,
      documentaryEvidenceStatus,
      mq,
      onPendingActionSubmit,
      goToDocumentaryEvidence,
      goToTargetRoute,
      goToError,
      agreeAllOwners,
      corpProfile,
      registeredName,
      isExtraInfoRequired,
      isAdditionalInformationHidden,
    }
  },
}
</script>

<style lang="postcss" scoped>
.type-my-subtitle {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  line-height: 1rem;
  font-size: 14px;
}

.welcome {
  max-width: 70vw;
  display: inline-block;
  @apply font-medium pr-1 truncate;
  @screen sm {
    max-width: 50vw;
  }
  @media screen and (min-width: 600px) and (max-width: 765px) {
    max-width: 35vw;
  }
  @screen md {
    max-width: 450px;
  }
}

.pending-actions {
  &-caption {
    &-bold {
      @apply type-caption-bold !important;
    }
  }

  /deep/ &-status {
    span {
      @apply text-xs;
    }
  }

  &-button {
    /deep/ .button-inner {
      @apply py-3 px-6 !important;
    }
  }

  &-footer {
    @apply pt-0;
    float: right;
    z-index: 50;
  }
}
</style>
